import Headroom from "headroom.js";

var myElement = document.querySelector("header");

var headroom = new Headroom(myElement);

headroom.init();

import VanillaTilt from "vanilla-tilt";

function vanillaTilt(item, max, speed) {
	let items = document.querySelectorAll(item)
	VanillaTilt.init((items), {max: max, speed: speed});
}

vanillaTilt('.installation__right', 1, 2000);

